import { FieldValues } from "react-hook-form";
import { supabase } from "../supabaseClient";
import { Feeds } from "../types/api/Feed";
import { MEDIA_SIZE, getMediaPathWithFilename } from "./files";
import { setup_notifications } from "./setup_notification";
import { SelectedFile } from "../types/PostSelection";
import { Post } from "../types/api/Post";

export async function schedule_update(
  feeds: Feeds,
  values: FieldValues,
  selected: SelectedFile[],
  existing_post: Post
) {
  let error = null;
  const {
    data: { session },
  } = await supabase.auth.getSession();

  const { data: post, error: postError } = await supabase
    .from("posts")
    .update({
      type: values.default.type,
      publish_at: new Date(values.date).toUTCString(),
      user_id: session?.user.id,
    })
    .eq("id", existing_post.id)
    .select()
    .order("id", { ascending: true })
    .limit(1)
    .single();

  error = postError;

  // DELETE EXISTING POSTS_FOR_FEEDS AND POSTS_MEDIA
  await supabase.from("posts_media").delete().eq("post", existing_post.id);
  await supabase.from("posts_for_feeds").delete().eq("post", existing_post.id);

  const imgs = Object.values(selected).sort((a, b) =>
    a?.order && b?.order ? a.order - b.order : 0
  );

  const media = [];

  for (const img of imgs) {
    const isVideo = img.mine_type.startsWith("video/");
    media.push({
      post_id: post?.id,
      post: post?.id,
      title: img.name,
      src_thumbnail: await getMediaPathWithFilename(
        MEDIA_SIZE.thumbnail,
        img.name
      ),
      src_2400: await getMediaPathWithFilename(
        isVideo ? MEDIA_SIZE.video : MEDIA_SIZE[2400],
        img.name
      ),
      src_3600: await getMediaPathWithFilename(
        isVideo ? MEDIA_SIZE.video : MEDIA_SIZE[3600],
        img.name
      ),
      src_original: await getMediaPathWithFilename(
        isVideo ? MEDIA_SIZE.video : MEDIA_SIZE.original,
        img.name
      ),
      mine_type: img.mine_type,
      user_id: session?.user.id,
      order: null,
    });
  }

  await supabase.from("posts_media").insert(media);

  const postsForFeeds = Object.values(feeds).map((feed) => ({
    post_id: post?.id,
    post: post?.id,
    feed_id: feed.id,
    feed: feed.id,
    content: values[feed.slug!].post_content,
    auto_publish: !!values[feed.slug!].auto_publish,
    type: values[feed.slug!].type,
    location: values[feed.slug!].location,
    user_id: session?.user.id,
  }));

  await supabase.from("posts_for_feeds").insert(postsForFeeds);

  await setup_notifications("update", post);

  return {
    error,
  };
}
