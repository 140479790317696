import React, { ReactElement, useEffect } from "react";
import { Link } from "react-router-dom";
import { initOneSignal } from "./onesignal/onesignal";
import { Icon, Home, Rss, User, Calendar } from "react-feather";
import { Toaster } from "react-hot-toast";
import cx from "classnames";

export function Layout({ children }: { children: ReactElement }) {
  useEffect(() => {
    initOneSignal();
  }, []);

  return (
    <>
      <Toaster />
      <div className="flex justify-start w-full h-screen relative max-lg:flex-col-reverse">
        <div className="bg-neutral py-6 px-2 h-full max-lg:h-auto max-lg:w-screen max-lg:py-2 max-lg:px-4 max-lg:shadow-md">
          <div className="max-lg:hidden">
            <a className="btn btn-ghost normal-case text-xl" href="/">
              <img src="/Logo128.png" className="w-9" />
            </a>
          </div>
          <div className="flex-none mt-2 max-lg:mt-0">
            <ul className="menu max-lg:menu-horizontal max-lg:menu-lg max-lg:justify-around max-lg:w-full">
              <MenuItem name="Planner" link="/" Icon={Calendar} />
              <MenuItem name="Feeds" link="/feeds" Icon={Rss} />
              <MenuItem name="Profile" link="/me" Icon={User} />
            </ul>
          </div>
        </div>
        <div className="relative z-0 w-full h-screen overflow-auto">
          {children}
        </div>
      </div>
    </>
  );
}

function MenuItem({
  name,
  link,
  Icon,
}: {
  name: string;
  link: string;
  Icon: Icon;
}) {
  const active = window.location.pathname === link;
  return (
    <li className="tooltip tooltip-right" data-tip={name}>
      <Link
        to={link}
        className={cx(
          "rounded-md py-2 mb-1 hover:bg-base-300",
          active && "bg-base-200"
        )}
      >
        <Icon className="h-5 w-5" />
      </Link>
    </li>
  );
}
