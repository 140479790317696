import { ChangeEvent, useEffect, useRef, useState } from "react";
import { supabase } from "../../supabaseClient";
import { handleError } from "../../utils/handleError";
import { Location, Locations } from "../../types/api/Location";
import { Globe } from "react-feather";

export function LocationSearch(props: {
  updateLocation: (location: Location) => void;
  initialLocation?: Location;
}) {
  const [value, setValue] = useState<string | null>(null);
  const [locations, setLocations] = useState<Locations>([]);
  const [filteredLocations, setFileredLocations] = useState<Locations>([]);

  useEffect(() => {
    async function asyncFct() {
      try {
        let { data: location_search, error: error1 } = await supabase
          .from("location_search")
          .select("*");

        if (error1) {
          throw error1;
        }

        if (location_search) setLocations(location_search);

        console.log("LOCATIONS", location_search);
      } catch (e) {
        handleError(e as Error);
      }
    }
    asyncFct();
  }, []);

  useEffect(() => {
    if (props.initialLocation && value === null) {
      setValue(
        `${props.initialLocation.name}, ${
          (props.initialLocation.details as any)?.country
        }`
      );
    }
  }, [props.initialLocation]);

  const search = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const query = event.target.value;
      const filtered = locations.filter((l) =>
        l.name?.toLowerCase().includes(query.toLowerCase())
      );

      console.log("SEARCH RESULTS", query, filtered);

      setValue(query);
      setFileredLocations(filtered);
    } catch (e) {
      handleError(e as Error);
    }
  };

  const selectLocation = (location: Location) => {
    const elem = document.activeElement as HTMLInputElement;
    if (elem) {
      elem?.blur();
    }
    setValue(`${location.name}, ${(location.details as any)?.country}`);
    props.updateLocation(location);
  };

  return (
    <div className="form-control w-full">
      <div className="dropdown">
        <label
          tabIndex={0}
          className="input input-bordered input-sm flex items-center gap-2 bg-base-100"
        >
          <Globe size={16} />
          <input
            type="text"
            placeholder=""
            className="grow"
            onChange={search}
            value={value ?? ""}
            autoComplete="off"
          />
        </label>

        <ul
          tabIndex={0}
          className="dropdown-content menu menu-sm bg-base-100 rounded-box z-[1] w-full p-2 shadow"
        >
          {filteredLocations.map((l) => (
            <li>
              <a
                className="prose flex-col cursor-pointer hover:bg-base-300"
                onClick={() => selectLocation(l)}
              >
                <div>
                  <span className="text-sm">{l.name}</span> <br />
                  <span className="text-xs">
                    {(l.details as any)?.city}, {(l.details as any)?.country}
                  </span>
                </div>
              </a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
}
