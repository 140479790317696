import React, { ReactElement, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { useForm } from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { ErrorAlert } from "../ErrorAlert";
import { Trash2 } from "react-feather";
import { SavedContent, SavedContents } from "../../types/api/SavedContent";
import { PostgrestError } from "@supabase/supabase-js";
import { SelectedFile } from "../../types/PostSelection";
import { Feed } from "../../types/api/Feed";

type Requirement = {
  minetype?: string[];
  min_ratio?: string;
  max_ratio?: string;
  max_filesize?: string;
};

type Requirements = {
  TEXT?: Requirement;
  PHOTO?: Requirement;
  VIDEO?: Requirement;
} | null;

export function CropCheck({
  feed,
  selectedFiles,
  postType,
}: {
  feed?: Feed;
  selectedFiles?: SelectedFile[];
  postType?: string;
}) {
  if (!feed || !feed.requirements || !selectedFiles || !postType) {
    return null;
  }

  const issues = validateMedia(feed, selectedFiles, postType);

  if (issues.length > 0) {
    return (
      <div role="alert" className="alert alert-warning">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          className="h-6 w-6 shrink-0 stroke-current"
          fill="none"
          viewBox="0 0 24 24"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="2"
            d="M12 9v2m0 4h.01m-6.938 4h13.856c1.54 0 2.502-1.667 1.732-3L13.732 4c-.77-1.333-2.694-1.333-3.464 0L3.34 16c-.77 1.333.192 3 1.732 3z"
          />
        </svg>
        <div className="text-sm">
          <ul>
            {issues.map((i) => (
              <li>
                "{i.file.name}" as the wrong mine type "{i.file.mine_type}"
              </li>
            ))}
          </ul>
        </div>
      </div>
    );
  }

  return null;
}

function validateMedia(
  feed: Feed,
  selectedFiles: SelectedFile[],
  postType: string
) {
  const typeMappings = feed.type_mapping as {
    [key: string]: { type: string; photo_type?: string };
  };
  const requirements = feed.requirements as Requirements;
  const mappedType = (typeMappings?.[postType]?.photo_type ??
    typeMappings?.[postType]?.type) as keyof Requirements;
  const requirement = requirements?.[mappedType] as Requirement | undefined;

  console.log("REQUIREMENTS", feed, feed.requirements, postType, requirement);

  const issues: {
    type: "MINETYPE" | "FILESIZE" | "RATIO";
    file: SelectedFile;
  }[] = [];

  for (const file of selectedFiles) {
    if (!requirement?.minetype?.includes(file.mine_type)) {
      issues.push({
        type: "MINETYPE",
        file,
      });
    }
  }

  return issues;
}
