import { useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import { SelectedFile } from "../../types/PostSelection";
import { ChevronLeft, ChevronRight, Crop } from "react-feather";
import { getMediaPath, getSignedUrl, MEDIA_SIZE } from "../../api/files";
import "./styles.scss";
import { Modal } from "../Modal";
import { handleError } from "../../utils/handleError";
import { useKeyDown } from "../../utils/useKeyDown";

let keydownHandler: null | ((event: KeyboardEvent) => void) = null;

export function PhotoViewer({
  open,
  close,
  selected,
  images,
}: {
  open: boolean;
  close: () => void;
  selected: string | null;
  images: SelectedFile[];
}) {
  const ref = useRef<HTMLDialogElement>(null);
  const [imgIndex, setImgIndex] = useState(-1);
  const [imgUrl, setImgUrl] = useState<string | null>();
  const [loading, setLoading] = useState(false);
  const selectedImg = images[imgIndex];
  const isVideo = selectedImg?.name?.includes(".video-thumbnail.jpg");

  useEffect(
    function () {
      if (open && selected && imgIndex === -1) {
        ref.current?.showModal();
        setImgIndex(images?.findIndex((i) => i._id === selected));
      }
    },
    [open, selected, images]
  );

  useEffect(
    function () {
      if (selectedImg) {
        console.log("GETTINGS IMAGES URL", selectedImg);
        setLoading(true);
        const isVideo = selectedImg.name.includes(".video-thumbnail.jpg");
        getFullResoltionImage(selectedImg, isVideo)
          .then((url) => {
            setImgUrl(url);
            console.log("GOT IMAGE URLS", url);
          })
          .catch((e) => handleError(e));
      }
    },
    [imgIndex]
  );

  const onImageLoad = () => {
    setLoading(false);
  };

  const closeModal = () => {
    ref.current?.close();
    setImgIndex(-1);
    close();
  };

  const prevImg = () => {
    setImgIndex((i) => (i === 0 ? images.length - 1 : i - 1));
  };

  const nextImg = () => {
    setImgIndex((i) => (i === images.length - 1 ? 0 : i + 1));
  };

  useKeyDown(prevImg, ["ArrowLeft"]);
  useKeyDown(nextImg, ["ArrowRight"]);

  return (
    <Modal
      show={open}
      onClose={closeModal}
      className="max-w-screen-2xl overflow-hidden"
      hideHeader
    >
      <>
        <button
          onClick={closeModal}
          className="btn btn-sm btn-circle btn-ghost absolute right-3 top-3"
        >
          ✕
        </button>
        <div className="photo-viewer-grid h-full w-full max-h-full">
          <button
            type="button"
            className="btn btn-circle photo-viewer-grid-button1"
            onClick={prevImg}
          >
            <ChevronLeft />
          </button>

          <div className="photo-viewer-grid-main h-full relative">
            {loading && (
              <div className="absolute h-full w-full">
                <div className="flex justify-center items-center h-full w-full">
                  <span className="loading loading-spinner loading-lg text-primary" />
                </div>
              </div>
            )}
            {imgUrl && !isVideo && (
              <img
                src={imgUrl}
                className="object-scale-down max-h-full"
                onLoad={onImageLoad}
              />
            )}
            {imgUrl && isVideo && (
              <video
                src={imgUrl}
                className="max-h-full"
                onLoadedMetadata={onImageLoad}
                controls
              />
            )}
          </div>

          <button
            type="button"
            className="btn btn-circle photo-viewer-grid-button2"
            onClick={nextImg}
          >
            <ChevronRight />
          </button>

          <div className="photo-viewer-grid-footer flex items-center gap-4">
            <span>{selectedImg?.name}</span>
            <button className="btn btn-sm btn-primary">
              <Crop size={18} /> Crop
            </button>
          </div>
        </div>
      </>
    </Modal>
  );
}

async function getFullResoltionImage(img: SelectedFile, isVideo: boolean) {
  const imagePath = await getMediaPath(
    isVideo ? MEDIA_SIZE.video : MEDIA_SIZE[3600]
  );
  return getSignedUrl(
    imagePath + "/" + img.name.replace(".video-thumbnail.jpg", "")
  );
}
