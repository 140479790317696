import React, { useEffect, useContext, useState } from "react";
import { AuthContext } from "./AuthProvider";
import { Layout } from "./Layout";
import { supabase } from "./supabaseClient";
import { FieldValues, useForm } from "react-hook-form";
import { handleError } from "./utils/handleError";

const Account = () => {
  const [updating, setUpdating] = useState("");
  const { session } = useContext(AuthContext);
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  useEffect(() => {
    getProfile();
  }, [session]);

  const getProfile = async () => {
    try {
      let { data, error, status } = await supabase
        .from("profiles")
        .select("*")
        .eq("id", session?.user?.id!)
        .single();

      if (error && status !== 406) {
        throw error;
      }

      if (data) {
        reset(data);
      }
    } catch (error) {
      handleError(error as Error);
    }
  };

  const updateProfile = async (content: FieldValues) => {
    setUpdating("profile");
    try {
      const updates = {
        id: session?.user.id,
        ...content,
      };

      let { error } = await supabase.from("profiles").upsert(updates);

      if (error) {
        throw error;
      }
      setUpdating("");
    } catch (error) {
      setUpdating("");
      handleError(error as Error);
    }
  };

  const importLocations = async (content: FieldValues) => {
    setUpdating("location");
    try {
      const jsonResults = JSON.parse(content.facebook_page_search);
      console.log("PARSED LOCATIONS", jsonResults);

      let { data: location_search, error: error1 } = await supabase
        .from("location_search")
        .select("id");

      if (error1) {
        throw error1;
      }

      const ids = location_search?.map((r) => r.id);

      console.log("IDS", ids);

      const newLocations = jsonResults
        .slice(0, 5)
        .filter((id: number) => !ids?.includes(id))
        .map((l: any) => ({
          location_id: l.id,
          name: l.name,
          details: l.location,
        }));

      console.log("LOCATIONS TO ADD", newLocations);

      let { error: error2 } = await supabase
        .from("location_search")
        .insert(newLocations);

      if (error2) {
        throw error2;
      }

      setUpdating("");
    } catch (e) {
      setUpdating("");
      handleError(e as Error);
    }
  };

  return (
    <Layout>
      <div className="bg-base-100">
        <div className="container mx-auto my-10 max-lg:px-6">
          <span className="prose">
            <h1 className="mb-8">Profile details</h1>
          </span>

          <div className="card w-full bg-neutral  mb-12 drop-shadow-xl">
            <div className="card-body">
              <form onSubmit={handleSubmit(updateProfile)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Name</span>
                  </label>
                  <input
                    placeholder="Name"
                    {...register("username", { required: true })}
                    className="input input-bordered w-full"
                  />
                  {errors.username && <span>This field is required</span>}
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Email</span>
                  </label>
                  <input
                    placeholder="Email"
                    value={session?.user.email}
                    disabled={true}
                    className="input input-bordered w-full"
                  />
                  {errors.email && <span>This field is required</span>}
                </div>

                <div className="form-control">
                  <label className="label">
                    <span className="label-text">Wesbite</span>
                  </label>
                  <input
                    placeholder="Website"
                    {...register("website")}
                    className="input input-bordered w-full"
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-4">
                  {updating === "profile" && (
                    <span className="loading loading-spinner"></span>
                  )}
                  Update Profile
                </button>
              </form>
            </div>
          </div>

          <span className="prose">
            <h1 className="mb-8">Import locations data</h1>
          </span>

          <div className="card w-full bg-neutral  mb-12 drop-shadow-xl">
            <div className="card-body">
              <p>Import a list of Facebook Search Page results.</p>

              <form onSubmit={handleSubmit(importLocations)}>
                <div className="form-control">
                  <label className="label">
                    <span className="label-text">JSON Data</span>
                  </label>
                  <textarea
                    {...register("facebook_page_search")}
                    className="input input-bordered w-full"
                    rows={15}
                  />
                </div>

                <button type="submit" className="btn btn-primary mt-4">
                  {updating === "location" && (
                    <span className="loading loading-spinner"></span>
                  )}
                  Import
                </button>
              </form>
            </div>
          </div>

          <span className="prose">
            <h1 className="mb-8">Sign Out</h1>
          </span>

          <div className="card w-full bg-neutral  mb-16 drop-shadow-xl">
            <div className="card-body">
              <button
                type="button"
                className="btn btn-error"
                onClick={() => supabase.auth.signOut()}
              >
                Sign Out
              </button>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default Account;
