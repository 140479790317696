import React, { useState, useContext, useEffect } from "react";
import { Layout } from "../../Layout";
import { supabase } from "../../supabaseClient";
import { AuthContext } from "../../AuthProvider";

// import { schedule_delete } from "../../api/schedule_delete";
import Calendar from "../../components/Calendar";
import { Card } from "../../components/Card/Card";
import { MediaPane } from "./MediaPane";

import "./styles.scss";
import { handleError } from "../../utils/handleError";
import { Post, Posts } from "../../types/api/Post";

export function Planner() {
  const [selectedPost, setSelectedPost] = useState<Post | null>(null);
  const { session } = useContext(AuthContext);
  const [calendarPosts, setCalendarPosts] = useState<Posts | null>([]);

  useEffect(() => {
    getPosts();
  }, [session]);

  const getPosts = async () => {
    let { data: posts, error } = await supabase.from("posts").select(
      `
        *,
        posts_media(*),
        posts_for_feeds(
          *,
          feed(*)
        )
      `
    );
    // .order("posts_media.order", "asc");
    if (error) handleError(error);

    if (!posts) return;

    setCalendarPosts(posts);
  };

  return (
    <Layout>
      <div className="px-6 py-6 lg:flex lg:h-screen lg:items-stretch">
        <Card
          noPadding
          notFull
          className="file-picker-card h-full mb-0 overflow-hidden max-lg:overflow-auto max-lg:w-full max-lg:h-auto"
        >
          <MediaPane
            updatePosts={getPosts}
            selectedPost={selectedPost}
            setSelectedPost={setSelectedPost}
          />
        </Card>

        <Card
          notFull
          noPadding
          className="h-full p-4 mb-0 ml-4 flex-grow max-lg:ml-0"
        >
          <Calendar posts={calendarPosts!} setSelectedPost={setSelectedPost} />
        </Card>
      </div>
    </Layout>
  );
}
