import React, {
  ReactElement,
  createRef,
  useCallback,
  useEffect,
  useState,
} from "react";
import { createPortal } from "react-dom";
import { X } from "react-feather";
import cx from "classnames";

type PropsArg = {
  show: boolean;
  title?: string;
  children: ReactElement;
  footer?: ReactElement;
  onClose: () => void;
  onSubmit?: () => void;
  className?: string;
  hideHeader?: boolean;
};

const modalRoot = document.getElementById("modal-root");

export function Portal({ children }: { children: ReactElement }) {
  const [elem, setElem] = useState<HTMLElement | null>(null);

  useEffect(() => {
    const node = document.createElement("div");
    modalRoot?.appendChild(node);
    setElem(node);
    return () => {
      modalRoot?.removeChild(node);
    };
  }, []);

  if (!elem) {
    return null;
  }

  return createPortal(children, elem);
}

export function Modal(props: PropsArg) {
  return (
    <Portal>
      <ModalChild {...props} />
    </Portal>
  );
}

function ModalChild({
  show,
  title,
  children,
  footer,
  onClose,
  onSubmit,
  className,
  hideHeader,
}: PropsArg) {
  const modalRef = createRef<HTMLInputElement>();

  const close = useCallback(() => {
    if (modalRef.current) modalRef.current.checked = false;
    onClose();
  }, [modalRef, onClose]);

  useEffect(() => {
    if (modalRef.current) modalRef.current.checked = show;
  }, [show, modalRef]);

  return (
    <>
      <input
        ref={modalRef}
        type="checkbox"
        id="post-modal"
        className="modal-toggle"
      />

      <div className="modal">
        <div
          className={cx(
            "modal-box bg-base-100 w-11/12 max-w-2xl lg:max-w-7xl relative",
            className
          )}
        >
          <div className="sticky top-0 right-6 z-10">
            <div className="flex justify-end w-full">
              <button
                type="button"
                className="btn btn-circle btn-sm shadow shadow-black"
                onClick={close}
              >
                <X />
              </button>
            </div>
          </div>
          <FormWrapper onSubmit={onSubmit}>
            <>
              {!hideHeader && (
                <>
                  <div className="flex justify-between items-center mb-6 -mt-8">
                    {title && <span className="prose">{title}</span>}
                  </div>
                </>
              )}

              {children}

              {footer && (
                <div className="modal-action flex flex-col md:flex-row md:align-middle md:justify-between">
                  {footer}
                </div>
              )}
            </>
          </FormWrapper>
        </div>
      </div>
    </>
  );
}

const FormWrapper = ({
  onSubmit,
  children,
}: {
  onSubmit?: () => void;
  children: ReactElement;
}) => {
  if (onSubmit) {
    return <form onSubmit={onSubmit}>{children}</form>;
  }
  return children;
};
