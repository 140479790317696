import { Location } from "../types/api/Location";

export function formatLocation(location: Location, isEmbeded?: boolean) {
    let formattedStr = `${location.name}, ${(location.details as any)?.country}`;

    if (isEmbeded) {
        formattedStr = `📍 ` + formattedStr;
    }

    return formattedStr;
}