import React from "react";
import { useEffect, useState } from "react";
import { getSignedUrl } from "../../api/files";
import "./styles.css";
import { Post } from "../../types/api/Post";
import { PostsMedia } from "../../types/api/PostMedia";

export const CalendarPost = ({
  post,
  setSelectedPost,
}: {
  post: Post;
  setSelectedPost: React.Dispatch<React.SetStateAction<Post | null>>;
}) => {
  return (
    <div
      className="calendar-post bg-primary rounded-md p-1 mb-2"
      onClick={() => {
        setSelectedPost(post);
      }}
    >
      <div className="flex gap-1">
        {post.posts_media?.slice(0, 3).map((m) => (
          <PostMedia key={m.id} media={m} />
        ))}
        {post.posts_media && post.posts_media?.length > 3 && (
          <div className="bg-slate-500 border-l-base-200 rounded w-6 h-6 overflow-hidden flex items-center justify-center">
            <span className="text-xs">+{post.posts_media.length - 3}</span>
          </div>
        )}
      </div>
    </div>
  );
};

const PostMedia = ({ media }: { media: PostsMedia }) => {
  const [url, setUrl] = useState<string | null>(null);
  const isVideo = media.mine_type?.startsWith("video");

  useEffect(() => {
    getSignedUrl(isVideo ? media.src_original! : media.src_thumbnail!).then(
      (signedUrl) => setUrl(signedUrl)
    );
  }, []);

  if (!url) return null;

  if (isVideo) {
    return (
      <div className="border-l-base-200 rounded w-6 h-6 overflow-hidden">
        <video src={url} className="object-fill w-full h-full" />
      </div>
    );
  }

  return (
    <div className="border-l-base-200 rounded w-6 h-6 overflow-hidden">
      <img src={url} alt="" className="object-fill w-full h-full" />
    </div>
  );
};
