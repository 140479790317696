import React, { ReactElement, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import {
  FieldValues,
  useForm,
  UseFormSetValue,
  UseFormWatch,
} from "react-hook-form";
import { supabase } from "../../supabaseClient";
import { ErrorAlert } from "../ErrorAlert";
import { ChevronDown, Crosshair, Delete, Plus, Trash2, X } from "react-feather";
import { SavedContent, SavedContents } from "../../types/api/SavedContent";
import { PostgrestError } from "@supabase/supabase-js";
import { Feed, Feeds } from "../../types/api/Feed";
import { getFeeds } from "../../api/feeds";
import { handleError } from "../../utils/handleError";

export function SavedDescriptions({
  open,
  close,
  updateContent,
  name,
  watch,
  setValue,
}: {
  open: boolean;
  close: () => void;
  updateContent: (desc: SavedContent) => void;
  name: string;
  watch: UseFormWatch<FieldValues>;
  setValue: UseFormSetValue<FieldValues>;
}) {
  const [savedContent, setSavedContent] = useState<SavedContents | null>([]);
  const [selected, setSelected] = useState<SavedContent | null>(null);
  const [apiError, setApiError] = useState<PostgrestError | null>(null);
  const [feeds, setFeeds] = useState<Feed[]>([]);

  useEffect(() => {
    getSavedContent();
    getFeedsList();
  }, []);

  const getSavedContent = async () => {
    const { data, error } = await supabase.from("saved_content").select();
    setApiError(error);
    setSavedContent(data as SavedContent[]);
    setSelected(null);
  };

  const getFeedsList = async () => {
    try {
      setFeeds(await getFeeds());
    } catch (e) {
      handleError(e as Error);
    }
  };

  const edit = (description: SavedContent) => {
    setSelected(description);
  };

  const addContentToDescription = (desc: SavedContent) => {
    updateContent(desc);
    close();
  };

  const addContentToAllFeedsDescriptions = (desc: SavedContent) => {
    if (name === "default") {
      setValue(
        "default.post_content",
        watch("default.post_content") +
          `{{VARIANTS(${desc.id}): "${desc.content}"}}`
      );
    } else {
      for (const f of feeds) {
        const field = `${f.slug!}.post_content`;
        setValue(
          field,
          watch(field) + (desc.variants[f.slug!] ?? desc.content)
        );
      }
    }

    close();
  };

  return createPortal(
    <>
      <input
        type="checkbox"
        id={`saved-content-modal-${name}`}
        className="modal-toggle"
        checked={open}
        readOnly
      />
      <div className="modal">
        <div className="modal-box w-11/12 max-w-md">
          <span className="prose">
            <h3 className="font-bold mb-2">Saved Descriptions</h3>
          </span>

          <ErrorAlert error={apiError} />

          {savedContent &&
            savedContent.map((desc) =>
              selected && selected.id === desc.id ? (
                <CardContainer key={desc.id}>
                  <NewOrEdit
                    id={desc.id}
                    name={desc.name!}
                    content={desc.content}
                    variants={desc.variants!}
                    setApiError={setApiError}
                    onUpdate={getSavedContent}
                    feeds={feeds}
                  />
                </CardContainer>
              ) : (
                <CardContainer key={desc.id}>
                  <>
                    <span className="prose">
                      <h3>{desc.name}</h3>
                    </span>

                    <p>{desc.content!}</p>

                    <div className="flex gap-2 w-full">
                      <button
                        type="button"
                        className="btn btn-primary btn-sm"
                        onClick={() => addContentToAllFeedsDescriptions(desc)}
                      >
                        Add to all
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline btn-sm"
                        onClick={() => addContentToDescription(desc)}
                      >
                        Add to current
                      </button>
                      <button
                        className="btn btn-ghost btn-sm"
                        onClick={() => edit(desc)}
                      >
                        Edit
                      </button>
                    </div>
                  </>
                </CardContainer>
              )
            )}

          <CardContainer>
            <div className="collapse p-0">
              <input type="checkbox" className="group h-7 min-h-7" />
              <div className="collapse-title font-medium p-0 h-7 min-h-7">
                <div className="flex items-center justify-between">
                  <span>Add a new Saved Description</span>
                  <div>
                    <ChevronDown className="group-checked:rotate-180" />
                  </div>
                </div>
              </div>
              <div className="collapse-content p-0">
                <NewOrEdit
                  setApiError={setApiError}
                  onUpdate={getSavedContent}
                  feeds={feeds}
                />
              </div>
            </div>
          </CardContainer>

          <div className="modal-action">
            <button type="button" className="btn" onClick={close}>
              Close
            </button>
          </div>
        </div>
      </div>
    </>,
    document.getElementById("saved-content-modal")!
  );
}

function CardContainer({ children }: { children: ReactElement }) {
  return (
    <div className="card bg-base-300 mb-4">
      <div className="card-body p-4">{children}</div>
    </div>
  );
}

function NewOrEdit({
  name,
  content,
  id,
  setApiError,
  onUpdate,
  feeds,
  variants,
}: {
  name?: string;
  content?: SavedContent["content"];
  id?: number;
  feeds?: Feeds;
  variants?: { [key: string]: string };
  setApiError: (err: PostgrestError | null) => void;
  onUpdate: () => Promise<void>;
}) {
  const variantRef = useRef(null);
  const [variantsList, setVariantsList] = useState<string[]>(
    variants ? Object.keys(variants) : []
  );

  const { register, unregister, getValues, reset, resetField } = useForm({
    defaultValues: {
      name: id ? name : null,
      content: id ? content : null,
      variants: variants ?? {},
    },
  });

  const onSubmit = async () => {
    const values = getValues();

    const { data: session } = await supabase.auth.getSession();

    const newContent: Omit<SavedContent, "id" | "created_at"> = {
      name: values.name!,
      content: values.content!,
      variants: values.variants,
      user_id: session.session?.user.id!,
    };

    const { error } = await supabase.from("saved_content").upsert({
      id,
      ...newContent,
    });
    setApiError(error);

    reset();
    onUpdate();
  };

  const deleteDesc = async () => {
    await supabase.from("saved_content").delete().eq("id", id!);
    await onUpdate();
  };

  const addVariant = () => {
    const value = (variantRef?.current as unknown as HTMLSelectElement)
      ?.value as string;
    console.log("ADD VARIANT", value);
    if (value) {
      setVariantsList((l) => [...l, value]);
    }
  };

  const removeVariant = (variant: string) => {
    unregister(`variants.${variant}`);
    setVariantsList(variantsList.filter((v) => v !== variant));
  };

  const filteredFeeds = feeds?.filter((f) => !variantsList.includes(f.slug!));

  return (
    <form>
      <div className="form-control">
        <label className="label">
          <span className="label-text">Name</span>
        </label>
        <input
          {...register("name", { required: true })}
          className="input input-bordered w-full mb-2"
        />
      </div>

      <div className="form-control">
        <label className="label">
          <span className="label-text">Description</span>
        </label>
        <textarea
          {...register("content")}
          className="textarea textarea-bordered w-full mb-2"
          rows={4}
        />
      </div>

      {variantsList.map((v) => (
        <div className="form-control">
          <div className="flex items-center justify-between">
            <label className="label">
              <span className="label-text">Variant: {v}</span>
            </label>
            <button
              type="button"
              className="btn btn-ghost btn-xs"
              onClick={() => removeVariant(v)}
            >
              <X />
            </button>
          </div>

          <textarea
            {...register(`variants.${v}`)}
            className="textarea textarea-bordered w-full mb-2"
            rows={4}
          />
        </div>
      ))}

      {filteredFeeds && filteredFeeds.length > 0 && (
        <div className="form-control">
          <label className="label">
            <span className="label-text">Add a variant</span>
          </label>
          <div className="flex items-center gap-4">
            <select
              className="select select-bordered flex-grow"
              ref={variantRef}
            >
              {filteredFeeds.map((f) => (
                <option value={f.slug!}>{f.name}</option>
              ))}
            </select>
            <button
              className="btn btn-outline"
              type="button"
              onClick={addVariant}
            >
              <Plus />
            </button>
          </div>
        </div>
      )}

      <div className="flex justify-between mt-6">
        <button type="button" className="btn btn-primary" onClick={onSubmit}>
          {!id ? "Add Saved Description" : "Update"}
        </button>
        {id && (
          <button type="button" className="btn btn-ghost" onClick={deleteDesc}>
            <Trash2 />
          </button>
        )}
      </div>
    </form>
  );
}
